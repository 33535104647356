const axios = require("axios").default;
const api = process.env.REACT_APP_SERVER_URL + "/api/";
const tokenKey = process.env.REACT_APP_TOKEN_KEY;

const getRequest = (endpoint, resolve, reject, token, callback, blob) => {
	handleRequestResponse(
		axios.get(api + endpoint, getHeaders(token, blob)),
		resolve,
		reject,
		callback,
		blob
	);
};

const postRequest = (endpoint, body, resolve, reject, token, callback) => {
	handleRequestResponse(
		axios.post(api + endpoint, body, getHeaders(token)),
		resolve,
		reject,
		callback
	);
};

const putRequest = (endpoint, body, resolve, reject, token, callback) => {
	handleRequestResponse(
		axios.put(api + endpoint, body, getHeaders(token)),
		resolve,
		reject,
		callback
	);
};

const deleteRequest = (endpoint, resolve, reject, token, callback) => {
	handleRequestResponse(
		axios.delete(api + endpoint, getHeaders(token)),
		resolve,
		reject,
		callback
	);
};

const handleRequestResponse = (
	request,
	resolve,
	reject,
	callback,
	blob = false
) => {
	request
		.then((res) => {
			if (callback) {
				callback(res.data.data, resolve);
			} else {
				if (blob) {
					const newBlob = new Blob([res.data], {
						type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
					});
					resolve(newBlob);
				} else {
					resolve(res.data.data);
				}
			}
		})
		.catch((err) => {
			handleError(err, reject);
		});
};

const handleError = (err, reject) => {
	console.log(err);
	if (!err.response) {
		return reject("Se presentó un error realizando la petición");
	}
	console.log(err.response);

	const {
		status,
		data: { errors },
	} = err.response;
	if (status === 400 || status === 404 || status === 401) {
		if (status === 401) {
			localStorage.clear();
			window.alert(errors[0]);
			window.location.reload();
		}

		if (errors) {
			return reject(errors[0]);
		}
	}
	return reject("Se presentó un error realizando la petición");
};

const getHeaders = (token, blob) => {
	const headers = {};
	if (token) {
		headers.Authorization = localStorage.getItem(tokenKey);
	}
	if (blob) {
		headers["Content-Type"] =
			"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
	}
	const configuration = {
		headers,
	};
	if (blob) {
		configuration.responseType = "blob";
	}
	return configuration;
};

module.exports = { getRequest, postRequest, putRequest, deleteRequest };
