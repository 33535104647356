import { lazy } from "react-router-guard";
import {
	noAuth,
	auth,
	noAuthOrValidRole,
	allAuths,
	institutionalRole,
	creationRole,
} from "./guards/guards";

export default [
	{
		path: "/",
		exact: true,
		canActivate: [noAuthOrValidRole],
		component: lazy(() => import("./containers/store")),
	},
	{
		path: "/order",
		exact: true,
		canActivate: [allAuths],
		component: lazy(() => import("./containers/storeResponse")),
	},
	{
		path: "/logIn",
		canActivate: [noAuth],
		component: lazy(() => import("./containers/login")),
	},
	{
		path: "/resetPassword",
		canActivate: [noAuth],
		component: lazy(() => import("./containers/resetPassword")),
	},
	{
		path: "/projection",
		canActivate: [auth],
		component: lazy(() => import("./layouts/projectionLayout")),
		routes: [
			{
				path: "/projection",
				exact: true,
				component: lazy(() => import("./containers/projection")),
			},
			{
				path: "/projection/analysis",
				exact: true,
				component: lazy(() => import("./containers/analysis")),
			},
		],
	},
	{
		path: "/dashboard",
		canActivate: [auth],
		component: lazy(() => import("./layouts/mainLayout")),
		routes: [
			{
				path: "/dashboard/orders",
				exact: true,
				component: lazy(() => import("./containers/orders")),
			},
			{
				path: "/dashboard/orders/details/:id",
				exact: true,
				component: lazy(() => import("./containers/orders/orderDetails")),
			},
			{
				path: "/dashboard/institutions",
				exact: true,
				component: lazy(() => import("./containers/institutions")),
			},
			{
				path: "/dashboard/institutions/details/:id",
				exact: true,
				component: lazy(() =>
					import("./containers/institutions/institutionDetails")
				),
			},
			{
				path: "/dashboard/isbns",
				exact: true,
				component: lazy(() => import("./containers/isbns")),
			},
			{
				path: "/dashboard/products/details/:id",
				exact: true,
				component: lazy(() => import("./containers/products/productDetails")),
			},
			{
				path: "/dashboard/configurations",
				exact: true,
				component: lazy(() => import("./containers/configurations")),
			},
		],
	},
	{
		path: "/institutional",
		exact: true,
		canActivate: [institutionalRole],
		component: lazy(() => import("./containers/storeInstitutional")),
	},
	{
		path: "/multiple",
		exact: true,
		canActivate: [creationRole],
		component: lazy(() => import("./containers/storeMultiple")),
	},
	{
		path: "/multiple/institutional",
		exact: true,
		canActivate: [creationRole],
		component: lazy(() => import("./containers/storeMultipleInstitutional")),
	},
	{
		path: "**",
		redirect: "/",
	},
];
